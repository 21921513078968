import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import showdown from "showdown";

const MarkdownConverter = new showdown.Converter({
  openLinksInNewWindow: true,
});

const MarkdownConverterRaw = new showdown.Converter({
  openLinksInNewWindow: true,
  extensions: [
    {
      type: "output",
      filter: (txt) => {
        const div = document.createElement("div");
        let finalTxt = "";
        div.innerHTML = txt;
        for (let i = 0; i < div.children.length; i += 1) {
          if (div.children[i].tagName.toLowerCase() === "p") {
            finalTxt += `${div.children[i].innerHTML}`;
          }
        }
        return finalTxt;
      },
    },
  ],
});

const Markdown = ({ className, useRaw, value }) => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    const converter = useRaw ? MarkdownConverterRaw : MarkdownConverter;
    setContent(converter.makeHtml(value));
  });

  return (
    // eslint-disable-next-line react/no-danger
    <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
  );
};

Markdown.propTypes = {
  className: PropTypes.string.isRequired,
  useRaw: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};

Markdown.defaultProps = {
  useRaw: false,
};

export default styled(Markdown)`
  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.3em;
  }
  strong,
  b {
    font-weight: bold;
  }
  i,
  em {
    font-style: italic;
  }
`;
