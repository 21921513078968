import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "../common/button";
import { useAppLink } from "../../utils/hooks";

const StyledPlanContentFooter = styled.div`
    position: absolute;
    bottom: 0;
    height: auto;
    left: 0;
    right: 0;
    padding: 25px 0;

    @media only screen and {
        position: relative;
        bottom: unset;
        margin-top: 30px;
    }
    > .svg-icon {
        margin-bottom: 5px;
        svg {
            height: 35px;
            fill: #0f75bf;
        }
    }
    > .selection-text {
        font-size: 13px;
        color: #0f75bf;
        margin: 0 10px;
    }
    > .subscribe {
        box-shadow: 0 0 25px rgba(255, 255, 255, 0.25);
        border-radius: 37px;
        color: white;
        margin: 0 auto;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 1.2em;
        padding: 1em 2em;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: capitalize;
        background-color: ${({ theme }) => theme.mainColor};
        :focus {
            background-color: #0f75bf;
        }
    }
`;

const PlanContentFooter = ({ planLink, startText, type }) => {
    let link = planLink;
    if (planLink.startsWith("/")) {
        link = useAppLink(planLink, true, true);
    }
    return (
        <StyledPlanContentFooter>
            <Button className="subscribe" href={link} eventName={`click_${type}_plan`}>
                {startText}
            </Button>
        </StyledPlanContentFooter>
    );
};

PlanContentFooter.propTypes = {
    planLink: PropTypes.string.isRequired,
    startText: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default PlanContentFooter;
