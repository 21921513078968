import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import CenterFrame from "../components/common/center-frame";
import LoadingPane from "../components/common/loading-pane";
import HtmlContent from "../components/common/html-content";
import DefaultTemplate from "../templates/default-template";
import PlanCardList from "../components/pricing/plan-card-list";
import Markdown from "../components/common/markdown";
import Button from "../components/common/button";
import { useLocationInitialization } from "../utils/hooks";
import theme from "../utils/theme";

const StyledBottomPane = styled.div`
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .bottom-content {
        max-width: 450px;
        text-align: center;
        h3 {
            margin-bottom: 20px;
            font-weight: bold;
        }
        p {
            margin: 20px 0;
            line-height: 1.2em;
        }
    }
    .contact-us-button {
        background-color: white;
        border-radius: 20px/50%;
        padding: 15px 30px;
    }
`;

export default styled(({ className, data, location, pageContext: { locale } }) => {
    const [dato, setDato] = useState(null);
    const [i18nData, setI18nData] = useState(null);

    useLocationInitialization(location);

    useEffect(() => {
        // eslint-disable-next-line react/prop-types
        setDato(data.allDatoCmsPagePricing.nodes[0]);
        // eslint-disable-next-line react/prop-types
        setI18nData(data.allDatoCmsI18N.nodes[0]);
    }, [data]);

    return (
        <>
            {i18nData ? (
                <DefaultTemplate
                    i18nData={i18nData}
                    locale={locale}
                    pathname={location.pathname}
                    title={i18nData.pricing}
                    seoMetaTags={data.allDatoCmsPagePricing.nodes[0].seoMetaTags}
                    colored
                >
                    <div className={className}>
                        {dato ? (
                            <CenterFrame className="main-content">
                                <div>
                                    <br />
                                    <HtmlContent html={dato.header} />
                                    <HtmlContent className="subheader" html={dato.subheader} />
                                    <br />
                                    <br />

                                    <PlanCardList
                                        plans={dato.plans}
                                        startText={dato.startText}
                                        locale={locale}
                                    />
                                    <StyledBottomPane>
                                        <div className="bottom-content">
                                            <Markdown value={dato.bottomText} />
                                            {dato.bottomButtonText && (
                                                <Button
                                                    className="contact-us-button"
                                                    data-action={
                                                        dato.bottomButtonDataAction || "contact_us"
                                                    }
                                                    data-lang={locale}
                                                    eventName="click_contact_us_prices"
                                                >
                                                    {dato.bottomButtonText}
                                                </Button>
                                            )}
                                        </div>
                                    </StyledBottomPane>
                                </div>
                            </CenterFrame>
                        ) : (
                            <div className="main-content">
                                <LoadingPane />
                            </div>
                        )}
                    </div>
                </DefaultTemplate>
            ) : null}
        </>
    );
})`
    .main-content {
        padding-top: 60px;

        padding-bottom: 150px;
        p {
            line-height: 140%;
        }
        h1 {
            font-size: 35px;
            margin: 20px 0 20px 0;
            color: #111111;
        }
        .inner {
            text-align: center;
            padding-top: 30px;
            padding-bottom: 30px;
        }
        .subheader {
            padding: 0 10%;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #111111;
        }
        h1,
        p,
        .interval-chooser {
            margin-left: 10px;
            margin-right: 10px;
            text-align: center;
        }
        h1 {
            margin: 40px 10px;
            font-weight: normal;
            strong {
                font-weight: 600;
                color: ${theme.mainColor};
            }
        }
        > p {
            font-size: 17px;
        }
        .interval-chooser {
            margin: 1.5em 0;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;
            align-items: center;
            letter-spacing: 0.285714px;
            color: #111111;
        }
        .interval-chooser > .interval-switch {
            margin: 20px 10px;
            .toggle-switch-label {
                background: #e6e6e6;
                box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.12);
                border-radius: 16px;
            }
            .toggle-switch-switch {
                border: none;
                background: linear-gradient(360deg, #1ab0f5 0%, rgba(255, 255, 255, 0) 100%),
                    #057be5;
                box-shadow: 0 15px 25px rgba(0, 119, 255, 0.15);
            }
        }

        @media (max-width: 768px) {
            h1 {
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                line-height: 30px;
                text-align: center;
            }
        }
    }
`;

export const query = graphql`
    query Pricing($locale: String) {
        allDatoCmsPagePricing(filter: { locale: { eq: $locale } }) {
            nodes {
                seoMetaTags {
                    ...GatsbyDatoCmsSeoMetaTags
                }
                header
                subheader
                monthlyBilling
                yearlyBilling
                # I have change startText to start to be more descriptive
                startText: start
                plans {
                    # type is a reserved keyword in DatoCMS but I think
                    # planType is just too descriptive codewise
                    type: planType
                    name
                    headline
                    monthlyPrice
                    monthlyPriceDetail
                    yearlyPrice
                    yearlyPriceDetail
                    commission
                    features
                    # I have change the variable name of link to planLink to
                    # avoid confusion from the Link component
                    planLink: link
                    icon {
                        url
                    }
                }
                bottomText
                bottomButtonDataAction
                bottomButtonText
            }
        }
        allDatoCmsI18N(filter: { locale: { eq: $locale } }) {
            nodes {
                ...HeaderData
                ...FooterData
                ...CookieBannerData
            }
        }
    }
`;
