import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledCard = styled.div`
    position: relative;
    border-radius: 10px;
    border: ${({ isSelected }) => (isSelected ? "3px solid #0F75BF" : "1px solid #ddd")};

    box-sizing: border-box;
    align-items: center;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.12);
`;

const StyledCardHeader = styled.div`
    position: relative;
    align-items: center;
    overflow: hidden;
    text-align: center;
    // Fix the div parent border when selected
    width: 100%;
    color: #111111;
    border-radius: ${({ isSelected }) => (isSelected ? 0 : "10px 10px 0 0")};
    box-sizing: border-box;
    height: 200px;
    > div.title {
        text-align: center;
        margin-top: 20px;
        svg {
            width: 40px;
            height: 40px;
        }
        font-size: 21px;
        font-weight: bold;
    }
    > p {
        text-align: center;
        font-weight: 600;
        margin-top: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
    > .type {
        font-size: 1.5em;
    }
    > .headline {
        font-weight: 300;
        text-transform: uppercase;
    }
    > .type.starter {
        padding-top: 0.25em;
    }
`;

const PlanCard = ({ type, name, icon, headline, children }) => {
    const lowercaseType = type.toLowerCase();

    return (
        <StyledCard className={`${lowercaseType} plan-card`}>
            <StyledCardHeader className={`${lowercaseType} plan-card-header`}>
                <div className="title">
                    <img src={icon} alt={`${type} icon`} />
                </div>
                <p className={`type ${lowercaseType}`}>{name}</p>
                <p className="headline">{headline}</p>
            </StyledCardHeader>
            {children}
        </StyledCard>
    );
};

PlanCard.propTypes = {
    icon: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default PlanCard;
