import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Markdown from "../common/markdown";
import PlanContentFooter from "./plan-content-footer";

const StyledPlanContent = styled.div`
    background-color: #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 10px 150px 10px;
    text-align: center;
    > .price {
        height: 65px;
        margin-bottom: 10px;
        > .title {
            font-size: 21px;
        }
        > .detail {
            margin-top: 5px;
            font-size: 13px;
        }
    }
    > p.commission {
        height: 35px;
    }
    > .feature-list {
        padding: 0 20px;
    }
    > .feature-list > ul {
        margin-top: 30px;
        text-align: left;
        font-size: 15px;
        > li {
            position: relative;
            // Merging li after and before borders
            margin: -3px 0;
            padding: 10px 0 10px 30px;
            line-height: 1.3em;
            &:before {
                content: "\\2714";
                color: ${({ theme }) => theme.mainColor};
                position: absolute;
                top: 10px;
                left: 5px;
                bottom: 0;
                width: 20px;
            }
            b,
            strong {
                font-weight: 600;
                color: ${({ theme }) => theme.mainColor};
            }
        }
    }
`;

const PriceSection = styled.div`
    width: 90%;
    text-align: center;
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    .title {
        font-size: 21px;
        b,
        strong {
            font-weight: 600;
            color: ${({ theme }) => theme.mainColor};
        }
    }
    .detail {
        margin-top: 5px;
        font-size: 13px;
        opacity: 0.5;
    }
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
`;

const PlanContent = ({ features, price, priceDetail, planLink, startText, type }) => (
    <StyledPlanContent>
        <PriceSection>
            <div>
                <Markdown className="title" value={price} />
                <Markdown className="detail" value={priceDetail} />
            </div>
        </PriceSection>
        <Markdown className="feature-list" value={features} />
        <PlanContentFooter planLink={planLink} startText={startText} type={type} />
    </StyledPlanContent>
);

PlanContent.propTypes = {
    price: PropTypes.string.isRequired,
    priceDetail: PropTypes.string.isRequired,
    features: PropTypes.string.isRequired,
    planLink: PropTypes.string.isRequired,
    startText: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
};

export default styled(PlanContent)``;
