import React from "react";
import styled from "styled-components";
import Icon from "./svg-icon";

const Loader = styled(({ className, color = "rgba(0, 0, 0, 0.2)" }) => (
    <div className={className}>
        <Icon icon="loading" color={color} /> 
    </div>
))`
    padding: 10px 60px;
    font-size: 200px;
    text-align: center;
`;

export default Loader;
