import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import PlanCard from "./plan-card";
import PlanContent from "./plan-content";

const StyledDiv = styled.div`
    display: grid;
    width: auto;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    padding: 20px;

    @media only screen and (max-width: ${({ theme }) => theme.contentWidth}) {
        grid-template-columns: 1fr;
        align-items: center;
    }
`;

const PlanCardList = ({ plans, startText }) => (
    <StyledDiv>
        {plans.map(
            ({
                type,
                name,
                headline,
                icon: { url },
                monthlyPrice,
                monthlyPriceDetail,
                commission,
                features,
                planLink,
            }) => (
                <PlanCard key={type} type={type} headline={headline} icon={url} name={name}>
                    <PlanContent
                        price={monthlyPrice}
                        priceDetail={monthlyPriceDetail || ""}
                        commission={commission}
                        features={features}
                        planLink={planLink}
                        startText={startText}
                        type={type}
                    />
                </PlanCard>
            )
        )}
    </StyledDiv>
);

PlanCardList.propTypes = {
    plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    startText: PropTypes.string.isRequired,
};

export default PlanCardList;
