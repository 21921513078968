import React from "react";
import PropTypes from "prop-types";

const HtmlContent = ({ html, className }) => (
    // eslint-disable-next-line react/no-danger
    <div className={className} dangerouslySetInnerHTML={{ __html: html }}/>
);
HtmlContent.propTypes = {
    html: PropTypes.string.isRequired,
    className: PropTypes.string
};

HtmlContent.defaultProps = {
    className: ""
};

export default HtmlContent;
