import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GoogleTag from "../../utils/google-tag";

const Button = ({ className, children, onClick, href, eventName, ...rest }) => {
  if (href) {
    return (
      <a
        className={className}
        href={href}
        onClick={() => GoogleTag.handle(eventName)}
        {...rest}
      >
        {children}
      </a>
    );
  }

  return (
    <button
      onClick={(e) => onClick(e)}
      className={className}
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.string,
  eventName: PropTypes.string,
};

Button.defaultProps = {
  className: "",
  href: null,
  onClick: null,
  eventName: null,
};

export default styled(Button)`
  border: 1px solid ${({ theme }) => theme.altColor};
  -webkit-appearance: none;
  writing-mode: horizontal-tb;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: 0.5em 1em;
  font-size: 0.75em;
  line-height: 1em;
  font-weight: bold;
  white-space: nowrap;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0;
  text-rendering: auto;
  vertical-align: middle;
  box-sizing: border-box;
  align-items: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  display: inline-block;
  border-radius: 5px;
  &:not(:disabled):hover {
    opacity: 0.8;
  }
  &:disabled {
    background-color: #eee;
    border-color: #ddd;
    color: #aaa;
  }
  &:not(:disabled) {
    cursor: pointer;
  }
  &:visited {
    color: inherit;
    background: inherit;
  }
  &:focus {
    outline: none;
  }
  &,
  &:focus,
  &:visited,
  &:link {
    color: ${({ theme }) => theme.altColor};
    background-color: white;
    background-color: ${({ theme }) => theme.mainColor};
    border-color: ${({ theme }) => theme.altColor};
  }
  svg {
    fill: ${({ theme }) => theme.altColor};
  }
`;
